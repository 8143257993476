import { Response } from 'express'
import Router from 'next/router'
import { buildCmsModuleComponents } from '@utils/index'
import getProperty from '@utils/getProperty'
import artworkMetaInfo from '@utils/metadata/artwork'
import artistMetaInfo from '@utils/metadata/artist'
import galleryMetaInfo from '@utils/metadata/gallery'
import eventMetaInfo from '@utils/metadata/event'
import roomMetaInfo from '@utils/metadata/room'
import specialRoomMetaInfo from '@utils/metadata/specialroom'
import pageNotFoundMetaInfo from '@utils/metadata/pageNotFoundMetaInfo'
import storyMetaInfo from '@utils/metadata/story'
import type IAppProps from 'types/IAppProps'
import type IURLContentData from 'types/IURLContentdata'
import contenthubMetaInfo from '@utils/metadata/contenthub'

// TODO: Explain in plain words what these function does, where is it called, etc.
export const doSomethingWithUrl =
  async (urlContentData: IURLContentData, props: IAppProps, res: Response, siteLanguage) => {
    const internalErrorHandler = () => {
      if (res) {
        res.statusCode = 500
      }
    }

    const applyPageModulesStructure = (cmsPageStructure: ABTypes.Pageviews.Pageview) => {
      props.componentCmsData = buildCmsModuleComponents(cmsPageStructure.modules, siteLanguage)
    }

    // only pageviews keep this. The rest of types are overwritten by metadatas
    const applyPageHeaderMetadata = () => {
      props.pageData = {
        openGraphData: {
          title: urlContentData?.data?.values?.page?.openGraphData?.title,
          description: urlContentData?.data?.values?.page?.openGraphData?.description,
          imageUrl: urlContentData?.data?.values?.page?.openGraphData?.imageUrl,
          url: urlContentData?.data.values?.page?.openGraphData?.url
         },
        type: urlContentData.data.type,
        uri: urlContentData.uri,
        externalTitle: urlContentData.data.values?.page?.externalTitle
      }
    }

    const handlers = {
      unauthorizedContent: (contentData: IURLContentData) => {
        if (res) {
          // ServerSide redirection
          res.redirect(`/access?redirect=${contentData.uri}`)
          // TODO: finished property is deprecated and has to be changed/removed
          res.finished = true
          res.end()
        } else {
          // client side next router redirection
          // if you dont have the access, then you have to login (not redirecting to dashboard)
          Router.push(
            {
              pathname: '/',
              query: {
                redirect: contentData.uri
              }
            },
            `/access?redirect=${contentData.uri}`,
            { shallow: false }
          )
        }
      },
      notFoundContent: async (contentData: IURLContentData) => {
        if (res) {
          res.statusCode = 404
        }
        // Covered in AB-320
        if (
          contentData?.uri?.includes('catalog/artwork') ||
          contentData?.uri?.includes('rooms/detail') ||
          contentData?.uri?.includes('rooms/specialsector')
        ) {
          props.pageData = await pageNotFoundMetaInfo({ urlContentData })
        } else {
          applyPageHeaderMetadata()
        }
      },
      shortlink: (contentData: IURLContentData) => {
        let redirectTo = getProperty(contentData, 'data.values.link.redirectionUrl')
        if (!redirectTo.startsWith('http') && redirectTo.charAt(0) !== '/') {
          redirectTo = `/${redirectTo}`
        }
        if (res) {
          res.set('Cache-control', 'public, max-age=600')
          res.redirect(301, redirectTo)
          res.finished = true
          res.end()
        } else if (redirectTo.charAt(0) === '/') {
          // internal/cms page
          Router.push(
            {
              pathname: '/'
            },
            redirectTo,
            { shallow: false }
          )
        } else {
          // external link
          window.location.href = redirectTo
        }
        return {}
      },
      pageview: (contentData: IURLContentData) => {
        applyPageModulesStructure(getProperty(contentData, 'data.values.page'))
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        applyPageHeaderMetadata()
      },
      artworkDetailPage: async (contentData: IURLContentData) => {
        applyPageModulesStructure(getProperty(contentData, 'data.values.page'))
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        // Set page metadata based on artwork data.
        props.pageData = await artworkMetaInfo({ urlContentData, props })
      },
      unavailableArtwork: async () => {
        if (res) res.statusCode = 404
        props.pageData = await pageNotFoundMetaInfo({ urlContentData })
      },
      unavailableRoom: async () => {
        if (res) res.statusCode = 404
        props.pageData = await pageNotFoundMetaInfo({ urlContentData })
      },
      unavailableSpecialRoom: async () => {
        if (res) res.statusCode = 404
        props.pageData = await pageNotFoundMetaInfo({ urlContentData })
      },
      artistDetailPage: async (contentData: IURLContentData) => {
        applyPageModulesStructure(getProperty(contentData, 'data.values.page'))
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await artistMetaInfo({ urlContentData })
      },
      galleryDetailPage: async (contentData: IURLContentData) => {
        props.componentCmsData = buildCmsModuleComponents(getProperty(contentData, 'data.values.page').modules, siteLanguage)
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await galleryMetaInfo({ urlContentData })
      },
      eventDetailPage: async (contentData: IURLContentData) => {
        applyPageModulesStructure(getProperty(contentData, 'data.values.page'))
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await eventMetaInfo({ urlContentData })
      },
      roomDetailPage: async (contentData: IURLContentData) => {
        applyPageModulesStructure(getProperty(contentData, 'data.values.page'))
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await roomMetaInfo({ urlContentData })
      },
      specialRoomDetailPage: async (contentData: IURLContentData) => {
        applyPageModulesStructure(getProperty(contentData, 'data.values.page'))
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await specialRoomMetaInfo({ urlContentData })
      },
      story: async (contentData: IURLContentData) => {
        props.componentCmsData = buildCmsModuleComponents(
          getProperty(contentData, 'data.values.article.modules'),
          siteLanguage
        )
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await storyMetaInfo({ urlContentData })
      },
      contentHubPage: async (contentData: IURLContentData) => {
        props.pageCmsData = getProperty(contentData, 'data.values.page')
        props.pageData = await contenthubMetaInfo({ urlContentData, siteLanguage })
      },
      deeplink: (contentData) => {
        const userAgent = res?.req?.headers['user-agent']
        let redirectTo = null

        if (userAgent) {
          const isIOS = /iPad|iPhone|iPod/.test(userAgent)
          const isAndroid = /android/i.test(userAgent)
          if (isIOS) {
            redirectTo = getProperty(contentData, 'data.values.link.ios')
          } else if (isAndroid) {
            redirectTo = getProperty(contentData, 'data.values.link.android')
          }
        }

        if (redirectTo) {
          if (res) {
            res.redirect(301, redirectTo)
            // TODO: finished property is deprecated and has to be changed/removed
            res.finished = true
            res.end()
          } else if (!res) {
            Router.push(
              {
                pathname: '/'
              },
              redirectTo,
              { shallow: false }
            )
          } else {
            window.location.href = redirectTo
          }
        }
        return {}
      }
    }

    if (urlContentData) {
      const urlType = getProperty(urlContentData, 'data.type')
      const handler = handlers[urlType]
      if (handler && typeof handler === 'function') {
        return await handler(urlContentData)
      }
    }
    internalErrorHandler()
  }

export default doSomethingWithUrl
