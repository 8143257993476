import { NextIncomingMessage } from 'next/dist/server/request-meta'

const getSiteHostName = (req: NextIncomingMessage | undefined): string => {
  let siteHostName
  if (req?.headers?.host) {
    siteHostName = req?.headers?.host
  }
  if (!req?.headers?.host && typeof window !== 'undefined' && window?.location?.hostname) {
    siteHostName = window?.location?.hostname
  }
  return siteHostName
}

export default getSiteHostName
