const data = {
  type: 'pageview',
  values: {
    page: {
      id: 21551,
      pageType: 'HOME_PAGE',
      internalTitle: '',
      externalTitle: '',
      dateCreated: '07/02/2022 08:29',
      dateUpdated: '30/08/2022 08:30',
      url: '/',
      schedule: false,
      description: '',
      imageUrl: '',
      accessGroups: [],
      displayTemplate: 'layout/default',
      pageTheme: 'LIGHT',
      openGraphData: {
        title: '',
        description: '',
        url: '/'
      },
      modules: [
        {
          id: 13182,
          name: 'Marc, Noah, Frank, Adeline',
          moduleId: -9135,
          fieldsTemplateId: -9135,
          moduleName: 'ChatBotModule',
          defaultLocale: 'en',
          order: 50,
          visible: true,
          accessGroups: [],
          fieldsList: [
            {
              key: 'info_grp1_body',
              values: [
                {
                  locale: 'en',
                  value:
                    '<p>Marc leads the organization’s development, including all three shows and our expanding artworld activities.</p>'
                },
                {
                  locale: 'zh_CN',
                  value:
                    '<p>Marc Spiegler 領導巴塞爾藝術展的整體發展，監督全球三地的展會業務，並致力推動集團於藝術界的拓展工作。</p><style> p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 24.0px \'FranklinGothic URW\'; color: #1d2327; -webkit-text-stroke: #1d2327} p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 18.0px \'FranklinGothic URW Light\'; color: #444749; -webkit-text-stroke: #444749} p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; font: 24.0px \'FranklinGothic URW\'; color: #1d2327; -webkit-text-stroke: #1d2327; min-height: 29.0px} p.p4 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px \'FranklinGothic URW Light\'; color: #444749; -webkit-text-stroke: #444749; min-height: 19.0px} p.p5 {margin: 0.0px 0.0px 0.0px 0.0px; font: 18.0px \'FranklinGothic URW Light\'; color: #444749; -webkit-text-stroke: #444749; background-color: #ffffff} span.s1 {font-kerning: none; background-color: #ffffff} span.s2 {font-kerning: none} </style>'
                }
              ]
            },
            {
              key: 'info_grp3_subtitle',
              values: [
                {
                  locale: 'en',
                  value: 'Commercial Director'
                },
                {
                  locale: 'zh_CN',
                  value: '商務總監'
                }
              ]
            },
            {
              key: 'info_grp4_cta_label',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp3_title',
              values: [
                {
                  locale: 'en',
                  value: 'Maia Guarnaccia'
                },
                {
                  locale: 'zh_CN',
                  value: 'Maia Guarnaccia'
                }
              ]
            },
            {
              key: 'info_grp4_body',
              values: [
                {
                  locale: 'en',
                  value: '<p>Frank manages Art Basel’s growing organization and structures its dynamic processes.</p>'
                },
                {
                  locale: 'zh_CN',
                  value: '<p>Frank Lasry 專責管理及優化巴塞爾藝術展不斷擴展中的企業架構。</p>'
                }
              ]
            },
            {
              key: 'info_grp1_title',
              values: [
                {
                  locale: 'en',
                  value: 'Marc Spiegler'
                },
                {
                  locale: 'zh_CN',
                  value: 'Marc Spiegler'
                }
              ]
            },
            {
              key: 'info_grp3_img',
              values: [
                {
                  locale: 'en',
                  value: 'https://d2u3kfwd92fzu7.cloudfront.net/asset/cms/Art_Basel_Maia_Maia_Guarnaccia-2.jpg'
                }
              ]
            },
            {
              key: 'info_grp1_cta_href',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp4_img',
              values: [
                {
                  locale: 'en',
                  value: 'https://d2u3kfwd92fzu7.cloudfront.net/asset/cms/Frank_Lasry-1.jpg'
                }
              ]
            },
            {
              key: 'info_grp1_cta_label',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp4_cta_href',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp4_subtitle',
              values: [
                {
                  locale: 'en',
                  value: 'Director Resources & Finance'
                },
                {
                  locale: 'zh_CN',
                  value: '財務及人力資源總監'
                }
              ]
            },
            {
              key: 'info_grp2_body',
              values: [
                {
                  locale: 'en',
                  value:
                    '<p>Clément Delépine leads the team of&nbsp;Paris+, par Art Basel, the upcoming art fair in the French capital.</p>'
                }
              ]
            },
            {
              key: 'info_grp4_title',
              values: [
                {
                  locale: 'en',
                  value: 'Frank Lasry'
                },
                {
                  locale: 'zh_CN',
                  value: 'Frank Lasry'
                }
              ]
            },
            {
              key: 'info_grp1_subtitle',
              values: [
                {
                  locale: 'en',
                  value: 'Global Director'
                },
                {
                  locale: 'zh_CN',
                  value: '全球總監'
                }
              ]
            },
            {
              key: 'info_grp2_cta_href',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp2_title',
              values: [
                {
                  locale: 'en',
                  value: 'Clément Delépine'
                },
                {
                  locale: 'zh_CN',
                  value: 'Clément Delépine'
                }
              ]
            },
            {
              key: 'info_grp3_cta_label',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp2_subtitle',
              values: [
                {
                  locale: 'en',
                  value: 'Director, Paris+, par Art Basel'
                }
              ]
            },
            {
              key: 'info_grp1_img',
              values: [
                {
                  locale: 'en',
                  value: 'https://d2u3kfwd92fzu7.cloudfront.net/asset/cms/Marc_Spiegler-1.jpg'
                }
              ]
            },
            {
              key: 'info_grp3_body',
              values: [
                {
                  locale: 'en',
                  value:
                    '<p>Maia Guarnaccia manages the development of new Art Basel initiatives and business opportunities.</p>'
                },
                {
                  locale: 'zh_CN',
                  value: '<p>Maia Guarnaccia管理及發展巴塞爾藝術展各個新項目及商業機會。</p>'
                }
              ]
            },
            {
              key: 'info_grp2_cta_label',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp2_img',
              values: [
                {
                  locale: 'en',
                  value: 'https://d2u3kfwd92fzu7.cloudfront.net/asset/cms/Cl_ment_Del_pine.jpg'
                }
              ]
            },
            {
              key: 'info_grp3_cta_href',
              values: [
                {
                  locale: 'en'
                }
              ]
            },
            {
              key: 'info_grp1_column_num',
              values: [
                {
                  locale: 'en',
                  value: '4'
                }
              ]
            }
          ],
          fields: {},
          displayPartial: 'layout/partials/ChatBotModule',
          offlineFlag: false,
          globalFlag: false,
          templateFlag: false
        }
      ],
      hideHeader: false,
      hideFooter: false,
      onlyForLargeScreens: false,
      layout: 'NORMAL'
    }
  }
}

export default data
