const data = {
  'type': 'deeplink',
  'values': {
    'link': {
      'ios': 'https://itunes.apple.com/us/app/art-basel-official-app/id1050156803?mt=8',
      'android': 'https://play.google.com/store/apps/details?id=com.mch.artbasel&hl=en'
    }
  }
}

export default data
