import { Cms as cmsService } from '@services/index'
import { logger } from '@utils/index'
import nextImagesData from '../../mockData/nextImagesData'
import chatBotData from '../../mockData/chatBotData'
import appData from '../../mockData/appData'
import { NextPageContext } from 'next'

// TODO: Explain in plain words what these function does, where is it called, etc.
export const getWhatToDoForUrl = async ({ req, uri, language }:
  { req: NextPageContext['req'], uri: string | undefined, language: string | null | undefined }):
  Promise<{ data: any, uri: any } | null> => {
  const isServer = typeof window === 'undefined'

  let targetUri = isServer ? req?.url : uri
  if (!targetUri?.includes('DraftId')) {
    targetUri = targetUri?.split('?')[0]
  }

  try {
    const urlContentData = await cmsService.getContentByUrl(encodeURI(targetUri || ''), language)
    if (urlContentData.ok) {
      // mock url which will return mock data (see: app/src/mockData)
      if (targetUri === '/playground/nextimages' && process.env.environment !== 'production') {
        return { data: nextImagesData, uri: targetUri }
      }
      if (targetUri?.includes('/playground/openai') && process.env.environment !== 'production') {
        return { data: chatBotData, uri: targetUri }
      }
      if (targetUri === ('/app')) {
        return { data: appData, uri: targetUri }
      }
      return { data: urlContentData.data, uri: targetUri }
    }
  } catch (e) {
    logger(e)
  }
  return null
}

export default getWhatToDoForUrl